import React from 'react';
import {
  Link
} from "react-router-dom";

function Header() {
  return (
    <nav className="navbar navbar-custom navbar-transparent navbar-light navbar-fixed-top">
      <div className="container">
        <ul className="extra-navbar nav navbar-nav navbar-right">
          <li><Link to={"/"} title="Home">Home</Link></li>
          <li><Link to={"/condolences"} title="Condolence Messages">Condolences</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
