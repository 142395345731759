import React from 'react';
import Loader from './components/loader'
import Header from './components/header'
import Body from './components/body'
import Footer from './components/footer'
import {
  BrowserRouter as Router
} from "react-router-dom";
import './App.css';

function App() {
  return (
    <Router>
      <Loader />
      <div className="wrapper">
        <Header />
        <Body />
        <Footer />
      </div>
      <div className="scroll-up">
        <a href="#totop"><i className="fa fa-angle-double-up"></i></a>
      </div>
    </Router>
  );
}

export default App;
