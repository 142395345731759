import React from 'react';
import {
	Link
} from "react-router-dom";
const $ = window.$;

function Home() {
	React.useEffect(() => {
		
		var worksgrid =  $('#works-grid');
		var windowWidth = Math.max($(window).width(), window.innerWidth),
			itemWidht = $('.grid-sizer').width(),
			itemHeight = Math.floor(itemWidht * 0.95),
			itemTallHeight = itemHeight * 2;

		if (windowWidth > 500) {
			$('.work-item', worksgrid).each(function () {
				if ($(this).hasClass('tall')) {
					$(this).css({
						height: itemTallHeight
					});
				} else if ($(this).hasClass('wide')) {
					$(this).css({
						height: itemHeight
					});
				} else if ($(this).hasClass('wide-tall')) {
					$(this).css({
						height: itemTallHeight
					});
				} else {
					$(this).css({
						height: itemHeight
					});
				}
			});
		} else {
			$('.work-item', worksgrid).each(function () {
				if ($(this).hasClass('tall')) {
					$(this).css({
						height: itemTallHeight
					});
				} else if ($(this).hasClass('wide')) {
					$(this).css({
						height: itemHeight / 2
					});
				} else if ($(this).hasClass('wide-tall')) {
					$(this).css({
						height: itemHeight
					});
				} else {
					$(this).css({
						height: itemHeight
					});
				}
			});
		}

		worksgrid.imagesLoaded(function () {
			worksgrid.isotope({
				layoutMode: 'packery',
				itemSelector: '.work-item',
				transitionDuration: '0.3s',
				packery: {
					columnWidth: '.grid-sizer',
				},
			});
		});

		$('.image-popup-vertical-fit').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-mobile',
			image: {
				verticalFit: true
			}
			
		});
	});
	return (
		<section id="portfolio" className="module">
			<div className="container">
				<div className="row">
					<div className="col-sm-12">
						<ul id="filters" className="filters font-alt">
							<li>Home</li>
							<li><Link to={"/condolences"} title="Condolence Messages" className="current">Condolences</Link></li>
						</ul>
					</div>
				</div>
				<div className="row">
					<div id="works-grid" className="works-grid works-hover-w">
						<div className="grid-sizer"></div>
						<div className="work-item design">
							<a className="image-popup-vertical-fit" href="assets/images/pics/profile.png">
								<img src="assets/images/pics/profile.png" alt="Denise's Profile" />
							</a>
						</div>
						<div className="work-item wide-tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/denise-grandad.png">
								<img src="assets/images/pics/denise-grandad.png" alt="Denise and Grandad" />
							</a>
						</div>
						<div className="work-item wide-tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/denise-fam.png">
								<img src="assets/images/pics/denise-fam.png" alt="Denise and the family" />
							</a>
						</div>
						<div className="work-item tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/1.jpeg">
								<img src="assets/images/pics/1.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/3.jpeg">
								<img src="assets/images/pics/3.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/4.jpeg">
								<img src="assets/images/pics/4.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/5.jpeg">
								<img src="assets/images/pics/5.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/6.jpeg">
								<img src="assets/images/pics/6.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item wide-tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/7.jpeg">
								<img src="assets/images/pics/7.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web wide">
							<a className="image-popup-vertical-fit" href="assets/images/pics/26.jpeg">
								<img src="assets/images/pics/26.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/15.jpeg">
								<img src="assets/images/pics/15.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item wide branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/9.jpeg">
								<img src="assets/images/pics/9.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/10.jpeg">
								<img src="assets/images/pics/10.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/11.jpeg">
								<img src="assets/images/pics/11.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item wide-tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/13.jpeg">
								<img src="assets/images/pics/13.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/14.jpeg">
								<img src="assets/images/pics/14.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/16.jpeg">
								<img src="assets/images/pics/16.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item wide branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/17.jpeg">
								<img src="assets/images/pics/17.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/18.jpeg">
								<img src="assets/images/pics/18.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/19.jpeg">
								<img src="assets/images/pics/19.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item wide-tall branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/20.jpeg">
								<img src="assets/images/pics/20.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web tall">
							<a className="image-popup-vertical-fit" href="assets/images/pics/21.jpeg">
								<img src="assets/images/pics/21.jpeg" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web">
							<a className="image-popup-vertical-fit" href="assets/images/pics/22.JPG">
								<img src="assets/images/pics/22.JPG" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web wide">
							<a className="image-popup-vertical-fit" href="assets/images/pics/23.JPG">
								<img src="assets/images/pics/23.JPG" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web tall">
							<a className="image-popup-vertical-fit" href="assets/images/pics/25.JPG">
								<img src="assets/images/pics/25.JPG" alt="" />
							</a>
						</div>
						<div className="work-item branding photo web wide-tall">
							<a className="image-popup-vertical-fit" href="assets/images/pics/24.JPG">
								<img src="assets/images/pics/24.JPG" alt="" />
							</a>
						</div>
					</div>
				</div>
				<div className="row m-t-70 text-center wow fadeInUp">
					<div className="col-sm-12">
						Gone but never forgotten, we love you Denise.
					</div>
				</div>
			</div>
		</section>
	);
}

export default Home;
