import React from 'react';

function Hero() {
  return (
		<section className="module-small p-b-0">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 text-center">
						<h1 className="mh-line-size-3 font-alt m-b-20">Denise George</h1>
						<h5 className="mh-line-size-4 font-alt">A loving sister, aunty, cousin and friend</h5>
					</div>
				</div>
			</div>
		</section>
  );
}

export default Hero;
