import React from 'react';
import Hero from '../Hero'
import Condolences from '../pages/condolences';
import Home from '../pages/home';
import {
  Route,
  Switch
} from "react-router-dom";

function Body() {
  return (
    <>
      <Hero />
      <Switch>
        <Route exact={true} path="/condolences">
          <Condolences />
        </Route>
        <Route path="*">
          <Home />
        </Route>
      </Switch>
    </>
  );
}

export default Body;
