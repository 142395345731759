import React from 'react';
import {
  Link
} from "react-router-dom";
import { BlobServiceClient } from '@azure/storage-blob';

function Condolences() {
  const sasToken = 'sv=2019-12-12&ss=b&srt=sco&sp=rl&se=3020-08-19T03:33:35Z&st=2020-08-18T19:33:35Z&spr=https&sig=fhOoKm14tRjEnodb%2BwErt%2F3goqjABngG0YtQPUn0SDk%3D';
  const accountUri = 'https://denisemessages.blob.core.windows.net/';
  const [messages, setMessages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      let messages = [];
      const blobServiceClient = new BlobServiceClient(`${accountUri}?${sasToken}`);
      const containerName = "messages";

      const containerClient = blobServiceClient.getContainerClient(containerName);

      let iter = await containerClient.listBlobsFlat();
      for await (const blob of iter) {
        const blobClient = containerClient.getBlobClient(blob.name);
        const downloadBlockBlobResponse = await blobClient.download();
        const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
        messages.push(JSON.parse(downloaded));
      }

      messages.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime())
      setMessages(messages);
      setIsLoading(false);

      async function blobToString(blob) {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
          fileReader.onloadend = (ev) => {
            resolve(ev.target.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsText(blob);
        });
      }

    }

    fetchData();
  }, []);

  let content = <div />;
  if (isLoading) {
    content = (
      <div className="row">
        <div className="col-sm-12 text-center">
          <h5 className="mh-line-size-4 font-alt">LOADING...</h5>
        </div>
      </div>
    )
  } else {
    content = messages.map((message, index) => {
      if (message != null) {
        const messageDate = new Date(message.Date);
        return (
          <div className="col-sm-6 col-md-4 col-lg-4 m-b-60" key={message.Date + index}>
            <div className="post">
              <div className="post-meta font-alt">
                {`From ${message.FirstName} ${message.LastName} / ${messageDate.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })}`}
              </div>
              <div className="post-header">
                <h4 className="post-title font-alt">
                  {message.Content}
                </h4>
              </div>
            </div>
          </div>
        )
      }
      return <div />
    });
  }

  return (
    <section className="module">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul id="filters" className="filters font-alt">
              <li><Link to={"/"} title="Home" className="current">Home</Link></li>
              <li>Condolences</li>
            </ul>
          </div>
        </div>
        <div className="row multi-columns-row">
          {content}
        </div>
      </div>
    </section>
  );
}

export default Condolences;
